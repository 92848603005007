import AssetTable from "@/components/Assets/AssetTable/index";
import { mapActions } from "vuex";

export default {
  components: {
    AssetTable,
  },
  data() {
    return {
      totalUSDT: "",
      GCCMatchAmount: 0,
      matchAmount: 0,
      modal: false,
      loginmsg: this.$t("common.loginTip"),
      loading: true,
      ordKeyword: "",
      tableMoney: [],
      tableMoneyShow: [],
      canMatch: true,
      modal_msg: false,
      match_msg: "",
      searchKey: "",
      tradeBaseCoin: [],
      showDollar: false,
      showAvailableAsset: false,
    };
  },
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      getWallet: "userCenter/WALLET_SERVICE_V2",
      getAllTradeBaseCoin: "exchange/GET_ALL_TRADE_BASE_COIN",
    }),
    getMoney() {
      this.getWallet()
        .then(async (response) => {
          this.totalUSDT = response.data.data.totalUsdBalance
          this.tableMoney = response.data.data.walletAssetsResponseDtoList;
          this.getTradeBaseCoins();
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    getTradeBaseCoins() {
      this.getAllTradeBaseCoin().then(async (response) => {
        let baseDate = {};
        baseDate = await response.data.data;
        for (let item in this.tableMoney) {
          for (let elem in baseDate) {
            if (this.tableMoney[item].unit === elem) {
              this.tableMoney[item]["baseCoinList"] = await baseDate[elem];
            }
          }
          if (!this.tableMoney[item]["baseCoinList"]) {
            this.tableMoney[item]["baseCoinList"] = [];
          }
        }
        this.tableMoneyShow = this.tableMoney;
        // for (let i = 0; i < this.tableMoney.length; i++) {
        //     this.tableMoney[i]["coinType"] = this.tableMoney[i].coin.unit;
        // }
        // else {
        //   this.$Message.error(this.loginmsg);
        // }
      })
      .finally(() => {
        this.loading = false;
      })
      .catch((err) => {
        this.$toast.error(err.message);
      });
    },
    filterTableData() {
      this.searchKey = this.searchKey.toUpperCase();
      this.tableMoneyShow = this.tableMoney.filter(
        (item) => {
          if (this.showAvailableAsset) {
            return (
              item.strBalance > 0 &&
              (item["unit"].includes(this.searchKey) ||
                item["name"].toUpperCase().includes(this.searchKey))
            );
          } else {
            return (
              item["unit"].includes(this.searchKey) ||
              item["name"].toUpperCase().includes(this.searchKey)
            );
          }
        }
        // this.showAvailableAsset
        //   ? item.strBalance > 0 &&
        //     (item["unit"].includes(this.searchKey.toUpperCase()) ||
        //       item["name"].toUpperCase().includes(this.searchKey.toUpperCase()))
        //   : item["unit"].includes(this.searchKey.toUpperCase()) ||
        //     item["name"].toUpperCase().includes(this.searchKey.toUpperCase())
      );
    },
  },
  created() {
    this.setNavigation("nav-assets");
    this.getMoney();
  },
};
