import {mapGetters, mapActions} from "vuex";
import Loader from "@/components/UI/LoadingComp"

export default {
    name: "asset-table",
    components: {
        Loader
    },
    props: {
        dataTable: {
            type: Array,
        },
        loading: {
            type: Boolean,
        },
        balanceType: {
            type: Boolean,
        },
    },
    data() {
        return {
            coinTypeDescriptionModal: false,
            tableColumnsMoneyHeader: [],
        };
    },
    mounted() {
        this.tableColumnsMoneyHeader = [
            {
                title: this.$t("assets.crypto"),
                slot: "coin",
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 180,
            },
            {
                title: this.$t("assets.balance"),
                key: "strBalance",
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 180,
                render: (h, params) => {
                    return h(
                        "span",
                        {
                            attrs: {
                                title: params.row.strBalance,
                            },
                        },
                        this.numberWithCommas(
                            params.row.strBalance,
                            params.row.frontCoinScale
                        )
                    );
                },
            },
            {
                key: "usd_irr",
                slot: "usd_irr",
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 180,
                renderHeader: (h) => {
                    return h("span", {}, this.balanceType ? this.$t("assets.usdBalance") : this.$t("assets.irrBalance"));
                },
            },
            // {
            //     title: this.$t("uc.finance.money.withdrawFee"),
            //     align: "center",
            //     render: (h, params) => {
            //         return h(
            //             "span",
            //             this.numberWithCommas(params.row.coin.maxTxFee)
            //         );
            //     }
            // },
            // {
            //     title: this.$t("uc.finance.money.availableBalance"),
            //     align: "center",
            //     minWidth: 150,
            //     render: (h, params) => {
            //         return h(
            //             "div",
            //             {
            //                 style: {
            //                     direction: "ltr"
            //                 }
            //             },
            //             parseFloat(params.row.strBalance) ? parseFloat(params.row.strBalance) - params.row.coin.maxTxFee < 0 ? "0" : this.numberWithCommas(parseFloat(params.row.strBalance) - params.row.coin.maxTxFee, params.row.coin.frontCoinScale) : "0"
            //         );
            //     }
            // },
            {
                title: this.$t("assets.frozen"),
                key: "strFrozenBalance",
                align: this.isLocaleLTR ? "left" : "right",
                minWidth: 140,
                render: (h, params) => {
                    return h(
                        "span",
                        {
                            attrs: {
                                title: params.row.strFrozenBalance,
                            },
                        },
                        this.numberWithCommas(
                            params.row.strFrozenBalance,
                            params.row.frontCoinScale
                        )
                    );
                },
            },
            {
                title: this.$t("assets.operation"),
                key: "operation",
                align: "center",
                minWidth: 180,
                render: (h, params) => {
                    let actions = [];
                    let data = [];
                    params.row.baseCoinList.forEach((item) => {
                        data.push(
                            h(
                                "DropdownItem",
                                {
                                    props: {
                                        name: item,
                                    },
                                    style: {
                                        color: "white",
                                    },
                                },
                                item
                            )
                        );
                    });
                    if (params.row.coinType === "VIRTUAL") {
                        actions.push(
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "text",
                                        size: "large",
                                    },
                                    style: {
                                        width: "130px",
                                        color: 'rgb(241, 172, 25)',
                                        borderColor: 'rgb(241, 172, 25)',
                                        backgroundColor: "transparent",
                                    },
                                    on: {
                                        click: () => {
                                            this.coinTypeDescriptionModal = true
                                        },
                                    },
                                },
                                this.$t("assets.trialCurrency")
                            ),
                        );
                    } else if (params.row.canTransfer === 1 && params.row.isLock === 0) {
                        // withdrawal;
                        actions.push(
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: '8px',
                                    },
                                    on: {
                                        click: () => {
                                            // this.getWithdrawStatus || this.getTransferStatus
                                            if (this.getWithdrawStatus) {
                                                this.$router.push(
                                                    "/transfer?name=" + params.row.unit
                                                );
                                            } else {
                                                this.$toast.error(this.$t("assets.disabledTransfer"));
                                            }
                                        },
                                    },
                                },
                                this.$t("assets.transfer")
                            )
                        );
                    } else {
                        actions.push(
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        disabled: true,
                                    },
                                    style: {
                                        marginRight: '8px',
                                    },
                                    on: {
                                        click: () => {
                                        },
                                    },
                                },
                                this.$t("assets.transfer")
                            )
                        );
                    }
                    if (params.row.coinType !== "VIRTUAL") {
                        actions.push(
                            h(
                                "Dropdown",
                                {
                                    attrs: {
                                        trigger: "click",
                                    },
                                    on: {
                                        "on-click": (name) => {
                                            let pair = `${params.row.unit.toLowerCase()}_${name.toLowerCase()}`;
                                            this.$router.push(`/exchange/${pair}`);
                                        },
                                    },
                                },
                                [
                                    h("DropdownMenu", {slot: "list"}, data),
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "text",
                                                size: "small",
                                                disabled: params.row.baseCoinList.length === 0,
                                            },
                                            style: {
                                                color:
                                                    params.row.baseCoinList.length === 0
                                                        ? `#555`
                                                        : "#2d8cf0",
                                                borderColor:
                                                    params.row.baseCoinList.length === 0
                                                        ? `#2c384f`
                                                        : "#2d8cf0",
                                                marginRight: "8px",
                                                backgroundColor:
                                                    params.row.baseCoinList.length === 0
                                                        ? `#222c3e`
                                                        : "transparent",
                                            },
                                        },
                                        this.$t("assets.trade")
                                    ),
                                ]
                            )
                        );
                    }

                    // if (!params.row.canAutoCheckWallet) {
                    //   actions.push(
                    //     h(
                    //       "Button",
                    //       {
                    //         attrs: {
                    //           id: "btn",
                    //         },
                    //         props: {
                    //           type: "text",
                    //           size: "small",
                    //         },
                    //         style: {
                    //           border: "none",
                    //           background: "none",
                    //           color: "#f1ac1c",
                    //           boxShadow: "unset",
                    //         },
                    //         on: {
                    //           click: () => {
                    //             document.getElementById(
                    //               "icon_" + params.row._rowKey
                    //             ).style.display = "none";
                    //             document.getElementById(
                    //               "spin_" + params.row._rowKey
                    //             ).style.display = "inline-block";
                    //             this.checkCoinWallet(params.row.unit)
                    //               .then((response) => {
                    //                 document.getElementById(
                    //                   "icon_" + params.row._rowKey
                    //                 ).style.display = "inline-block";
                    //                 document.getElementById(
                    //                   "spin_" + params.row._rowKey
                    //                 ).style.display = "none";
                    //                 this.$toast.success(response.data.message);
                    //                 this.$emit(
                    //                   "getCheckWalletData",
                    //                   response.data.data
                    //                 );
                    //               })
                    //               .catch((err) => {
                    //                 document.getElementById(
                    //                   "icon_" + params.row._rowKey
                    //                 ).style.display = "inline-block";
                    //                 document.getElementById(
                    //                   "spin_" + params.row._rowKey
                    //                 ).style.display = "none";
                    //                 this.$toast.error(err.message);
                    //               });
                    //           },
                    //         },
                    //       },
                    //       [
                    //         h(
                    //           "Tooltip",
                    //           {
                    //             props: {
                    //               placement: "left",
                    //               content: this.$t("assets.checkWallet"),
                    //               theme: "light",
                    //               delay: 100,
                    //             },
                    //           },
                    //           [
                    //             h("Icon", {
                    //               props: {
                    //                 type: "md-refresh-circle",
                    //                 size: 22,
                    //               },
                    //               attrs: {
                    //                 id: "icon_" + params.row._rowKey,
                    //               },
                    //               style: {
                    //                 display: "block",
                    //               },
                    //             }),
                    //           ]
                    //         ),
                    //         h("Spin", {
                    //           attrs: {
                    //             id: "spin_" + params.row._rowKey,
                    //           },
                    //           style: {
                    //             display: "none",
                    //             width: "22px",
                    //           },
                    //         }),
                    //       ]
                    //     )
                    //   );
                    // }
                    return h("p", actions);
                },
            },
        ]
    },
    computed: {
        ...mapGetters({
            getWithdrawStatus: "global/GET_WITHDRAW_STATUS",
            // getTransferStatus: "global/GET_TRANSFER_STATUS",
            // getDepositStatus: "global/GET_DEPOSIT_STATUS",
        }),
    },
    methods: {
        ...mapActions({
            checkCoinWallet: "userCenter/CHECK_COIN_WALLET",
        }),
        setDefaultLogo(e) {
            e.target.src = require("@/assets/images/coin_place_holder.png");
        }
    },
};
